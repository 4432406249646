import React, { useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ChainId, NETWORK_LABELS, NETWORK_URLS, EXPLORER_URLS, NATIVE_CURRENCY, SUPPORTED_CHAIN_IDS } from '../../constants/chains'
import { useWeb3React } from '@web3-react/core'

const NetworkButton = styled.button`
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme }) => theme.text1};
  border: none;
  border-radius: 12px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 12px;
  &:hover {
    background-color: ${({ theme }) => theme.bg4};
  }
`

const NetworkList = styled.div`
  position: absolute;
  margin-top: 10px;
  background-color: ${({ theme }) => theme.bg1};
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 12px;
  padding: 8px;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    bottom: 100%;
    left: 0;
    margin-bottom: 10px;
  `}

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    left: -10px;
    right: -10px;
  `}
`

const NetworkItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.bg2};
  }
`

const NetworkSelectorWrapper = styled.div`
  position: relative;
`

export default function NetworkSelector() {
  const { chainId, connector } = useWeb3React()
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setIsOpen(false))

  const switchNetwork = async (targetChainId: ChainId) => {
    if (!connector) {
      console.error('No connector found')
      return
    }
    try {
      const provider = await connector.getProvider()
      if (provider && provider.request) {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${targetChainId.toString(16)}` }],
        })
      } else {
        console.error('Provider does not support "request" method')
      }
      setIsOpen(false)
    } catch (switchError: any) {
      if (switchError.code === 4902) {
        try {
          const provider = await connector.getProvider()
          if (provider && provider.request) {
            await provider.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: `0x${targetChainId.toString(16)}`,
                  chainName: NETWORK_LABELS[targetChainId],
                  nativeCurrency: NATIVE_CURRENCY[targetChainId],
                  rpcUrls: [NETWORK_URLS[targetChainId]],
                  blockExplorerUrls: [EXPLORER_URLS[targetChainId]],
                },
              ],
            })
          }
        } catch (addError) {
          console.error('Failed to add network:', addError)
        }
      }
      console.error('Failed to switch networks:', switchError)
    }
  }

  return (
    <NetworkSelectorWrapper ref={ref}>
      <NetworkButton onClick={() => setIsOpen(!isOpen)}>
        {chainId && chainId in ChainId ? NETWORK_LABELS[chainId as ChainId] : 'Unknown Network'}
      </NetworkButton>
      {isOpen && (
        <NetworkList>
          {Object.values(SUPPORTED_CHAIN_IDS).filter(id => typeof id === 'number').map((id) => (
            <NetworkItem key={id} onClick={() => switchNetwork(id as ChainId)}>
              {NETWORK_LABELS[id as ChainId]}
            </NetworkItem>
          ))}
        </NetworkList>
      )}
    </NetworkSelectorWrapper>
  )
}
