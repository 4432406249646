import { useCallback } from 'react'
import { Currency } from '@uniswap/sdk-core'
import { useActiveWeb3React } from './web3'
import { useV2RouterContract } from './useContract'
import { useHoneyPotChecker, HoneyCheckResult } from './useHoneyPotChecker'

export function useTokenTaxes(currency?: Currency) {
  const { chainId } = useActiveWeb3React()
  const router = useV2RouterContract()
  const { checkHoneypot } = useHoneyPotChecker()

  const getTaxInfo = useCallback(async (): Promise<HoneyCheckResult> => {
    if (!currency || !chainId || !router || currency.isNative) {
      return {
        buyTax: null,
        sellTax: null,
        isHoneypot: false,
        hasLiquidity: false
      }
    }

    try {
      return await checkHoneypot(currency.wrapped.address, router.address)
    } catch (error) {
      console.error('Failed to fetch tax info:', error)
      return {
        buyTax: null,
        sellTax: null,
        isHoneypot: false,
        hasLiquidity: false
      }
    }
  }, [currency, chainId, router, checkHoneypot])

  return { getTaxInfo }
}
