import { useCallback } from 'react'
import { useActiveWeb3React } from './web3'
import { Contract } from '@ethersproject/contracts'
import { AddressZero } from '@ethersproject/constants'
import JSBI from 'jsbi'

const FACTORY_ABI = [
  'function getPair(address tokenA, address tokenB) external view returns (address pair)'
]

const PAIR_ABI = [
  'function balanceOf(address account) external view returns (uint256)',
  'function totalSupply() external view returns (uint256)'
]

const BURN_ADDRESSES = [
  AddressZero,
  '0x000000000000000000000000000000000000dEaD'
]

export function useLiquidityBurnCheck() {
  const { library, chainId } = useActiveWeb3React()

  const checkLiquidityBurned = useCallback(async (
      tokenAddress: string, 
      factoryAddress: string,
      wethAddress: string
  ): Promise<{ isLiquidityBurned: boolean, burnPercentage: number }> => {
      if (!library || !chainId) {
          throw new Error('Web3 not initialized')
      }

      try {
          const factory = new Contract(factoryAddress, FACTORY_ABI, library)
          console.log('diobonobo')
          const pairAddress = await factory.getPair(tokenAddress, wethAddress)
          console.log('diobonobo')

          if (pairAddress === AddressZero) {
              return { isLiquidityBurned: false, burnPercentage: 0 }
          }
          console.log('diobonobo')

          const pair = new Contract(pairAddress, PAIR_ABI, library)
          console.log('diobonobo')
          const totalSupply = await pair.totalSupply()

          console.log('diobonobo')
          let burnedLP = JSBI.BigInt(0)
          for (const burnAddress of BURN_ADDRESSES) {
              const balance = await pair.balanceOf(burnAddress)
              burnedLP = JSBI.add(burnedLP, JSBI.BigInt(balance.toString()))
          }

          const burnPercentage = JSBI.divide(
              JSBI.multiply(burnedLP, JSBI.BigInt(10000)),
              JSBI.BigInt(totalSupply.toString())
          )
          const burnPercentageNumber = JSBI.toNumber(burnPercentage) / 100

          const isLiquidityBurned = burnPercentageNumber > 99

          return { isLiquidityBurned, burnPercentage: burnPercentageNumber }
      } catch (error) {
          console.error('Liquidity burn check failed:', error)
          return { isLiquidityBurned: false, burnPercentage: 0 }
      }
  }, [library, chainId])

  return { checkLiquidityBurned }
}
