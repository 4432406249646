import { ChainId, NATIVE_CURRENCY } from './chains'
import { Token } from '../sdk-core/entities/token'
import { NativeCurrency } from '../sdk-core/entities/nativeCurrency'
import invariant from 'tiny-invariant'
import { WETH9_ADDRESS } from './addresses'

export const WBOME = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, WETH9_ADDRESS[ChainId.MAINNET], 18, 'WBOME', 'Wrapped Bome'),
  [ChainId.TESTNET]: new Token(ChainId.TESTNET, WETH9_ADDRESS[ChainId.TESTNET], 18, 'WBOME', 'Wrapped Bome'),
  [ChainId.ZKCRO]: new Token(ChainId.ZKCRO, WETH9_ADDRESS[ChainId.ZKCRO], 18, 'WZKCRO', 'Wrapped ZKCRO'),
}
export const WETH9 = WBOME

export class Okb extends NativeCurrency {
  protected constructor(chainId: number) {
    const native = NATIVE_CURRENCY[chainId as ChainId]
    super(chainId, native.decimals, native.symbol, native.name)
  }

  public get wrapped(): Token {
    const weth9 = WBOME[this.chainId as ChainId]
    invariant(!!weth9, 'WRAPPED')
    return weth9
  }

  private static _etherCache: { [chainId: number]: Okb } = {}

  public static onChain(chainId: number): Okb {
    return this._etherCache[chainId] ?? (this._etherCache[chainId] = new Okb(chainId))
  }

  public equals(other: NativeCurrency | Token): boolean {
    return other.isNative && other.chainId === this.chainId
  }
}
