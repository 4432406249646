export enum ChainId {
  MAINNET = 9736,
  TESTNET = 195,
  ZKCRO = 388,
}

export const SUPPORTED_CHAIN_IDS = [ChainId.MAINNET, ChainId.ZKCRO]

export const DEFAULT_CHAIN_ID = ChainId.ZKCRO

export const NETWORK_LABELS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: 'Bome',
  [ChainId.TESTNET]: 'Okb Testnet',
  [ChainId.ZKCRO]: 'zkEVM Cronos',
}

export const NETWORK_URLS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: 'https://rpc.bomechain.org',
  [ChainId.TESTNET]: 'https://testrpc.bomechain.org',
  [ChainId.ZKCRO]: 'https://mainnet.zkevm.cronos.org',
}

export const EXPLORER_PREFIXES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: 'bomescan.org',
  [ChainId.TESTNET]: 'www.okx.com/explorer/xlayer-test',
  [ChainId.ZKCRO]: 'explorer.zkevm.cronos.org',
}

export const EXPLORER_URLS: { [chainId in ChainId]: string } = Object.fromEntries(
  Object.entries(EXPLORER_PREFIXES).map(([chainId, prefix]) => [
    chainId,
    prefix ? `https://${prefix}` : '', // Handle cases where prefix might be empty
  ])
) as { [chainId in ChainId]: string };

export const NATIVE_CURRENCY: { [chainId in ChainId]: { name: string; symbol: string; decimals: number } } = {
  [ChainId.MAINNET]: { name: 'BOME', symbol: 'BOME', decimals: 18 },
  [ChainId.TESTNET]: { name: 'OKB', symbol: 'OKB', decimals: 18 },
  [ChainId.ZKCRO]: { name: 'ZKCRO', symbol: 'ZKCRO', decimals: 18 },
}
