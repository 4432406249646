import { useCallback } from 'react'
import { useActiveWeb3React } from './web3'
import { Contract } from '@ethersproject/contracts'
import { parseEther } from '@ethersproject/units'

const HONEY_CHECKER_ADDRESS = '0x37FAC9DA6343A8c8cc0659E428C38A3d32c5183B'
const SIMULATION_ADDRESS = '0xf47084C4c1fa4d863EE020bd08095Ce875215D14'
const HONEY_CHECKER_ABI = [
  'function honeyCheck(address targetTokenAddress, address idexRouterAddres) external payable returns (tuple(uint256 buyResult, uint256 tokenBalance2, uint256 sellResult, uint256 buyCost, uint256 sellCost, uint256 expectedAmount))'
]

export interface HoneyCheckResult {
  buyTax: number | null
  sellTax: number | null
  isHoneypot: boolean
  hasLiquidity: boolean
}

export function useHoneyPotChecker() {
  const { library, chainId } = useActiveWeb3React()

  const checkHoneypot = useCallback(
    async (tokenAddress: string, routerAddress: string): Promise<HoneyCheckResult> => {
      if (!library || !chainId) {
        throw new Error('Web3 not initialized')
      }

      try {
        const honeyCheckerContract = new Contract(HONEY_CHECKER_ADDRESS, HONEY_CHECKER_ABI, library)

        const buyAmount = parseEther("0.001")
        const result = await honeyCheckerContract.callStatic.honeyCheck(tokenAddress, routerAddress, { 
          from: SIMULATION_ADDRESS,
          value: buyAmount,
          gasLimit: 4500000
        })

        const buyFeePercentage = (1 - (Number(result.buyResult) / Number(result.expectedAmount))) * 100
        const sellFeePercentage = (1 - (Number(result.sellResult) / Number(buyAmount))) * 100 - buyFeePercentage
        const hasLiquidity = Number(result.expectedAmount) > 0
        const isHoneypot = hasLiquidity && Number(result.sellResult) === 0

        console.log('Raw results:', JSON.stringify({
          buyResult: result.buyResult.toString(),
          sellResult: result.sellResult.toString(),
          expectedAmount: result.expectedAmount.toString(),
          buyFeePercentage,
          sellFeePercentage,
          hasLiquidity,
          isHoneypot
        }))

        return {
          buyTax: hasLiquidity ? Math.max(0, buyFeePercentage) : null,
          sellTax: hasLiquidity ? Math.max(0, sellFeePercentage) : null,
          isHoneypot,
          hasLiquidity
        }
      } catch (error) {
        console.error('Honeypot check failed:', error)
        return {
          buyTax: null,
          sellTax: null,
          isHoneypot: false,
          hasLiquidity: false
        }
      }
    },
    [library, chainId]
  )

  return { checkHoneypot }
}
