import { useState, useEffect } from 'react'
import { Currency } from '@uniswap/sdk-core'
import { useTokenTaxes } from './useTokenTaxes'
import { useLiquidityBurnCheck } from './useLiquidityBurnCheck'
import { useV2RouterContract } from './useContract'
import { V2_FATORY_ADDRESS } from 'constants/addresses'
import { WBOME } from 'constants/native-token'
import { ChainId } from 'constants/chains'

export interface TokenScanResult {
  buyTax: number | null
  sellTax: number | null
  isHoneypot: boolean
  hasLiquidity: boolean
  isLiquidityBurned: boolean
  liquidityBurnPercentage: number
  warning: string | null
  isLoading: boolean
}

export function useTokenScanner(currency?: Currency) {
  const [scanResult, setScanResult] = useState<TokenScanResult>({
    buyTax: null,
    sellTax: null,
    isHoneypot: false,
    hasLiquidity: false,
    isLiquidityBurned: false,
    liquidityBurnPercentage: 0,
    warning: null,
    isLoading: false
  })

  const { getTaxInfo } = useTokenTaxes(currency)
  const { checkLiquidityBurned } = useLiquidityBurnCheck()
  const router = useV2RouterContract()

  useEffect(() => {
    if (!currency || currency.isNative || !router) {
      setScanResult(prev => ({ ...prev, isLoading: false }))
      return
    }

    const chainId = currency?.chainId
    const factory = V2_FATORY_ADDRESS[chainId as ChainId]
    const weth = WBOME[chainId as ChainId]

    const scanToken = async () => {
        setScanResult(prev => ({ ...prev, isLoading: true }))
        try {
            const [taxInfo, liquidityBurnInfo] = await Promise.all([
                getTaxInfo().catch(error => {
                    console.error('Tax info fetch failed:', error)
                    return { buyTax: null, sellTax: null, isHoneypot: false, hasLiquidity: false }
                }),
                checkLiquidityBurned(currency.wrapped.address, factory, weth.address).catch(error => {
                    console.error('Liquidity burn check failed:', error)
                    return { isLiquidityBurned: false, burnPercentage: 0 }
                })
            ])

        let warning = null
        if (!taxInfo.hasLiquidity) {
          warning = "This token has no liquidity pool on this DEX. Unable to estimate taxes."
        } else if (taxInfo.isHoneypot) {
          warning = "This token appears to be a honeypot. Exercise extreme caution!"
        } else if (taxInfo.buyTax !== null && taxInfo.sellTax !== null) {
          if (taxInfo.buyTax > 10 || taxInfo.sellTax > 10) {
            warning = "This token has unusually high taxes. Trade carefully."
          } else if (taxInfo.buyTax > 5 || taxInfo.sellTax > 5) {
            warning = "This token has higher than average taxes. Please be aware."
          }
        }

        if (!liquidityBurnInfo.isLiquidityBurned) {
          warning = (warning ? warning + " " : "") + "Liquidity is not burned, which may pose a risk."
        }

        setScanResult({
          ...taxInfo,
          ...liquidityBurnInfo,
          liquidityBurnPercentage: liquidityBurnInfo.burnPercentage,
          warning,
          isLoading: false
        })
        } catch (error) {
            console.error('Token scan failed:', error)
            setScanResult({
                buyTax: null,
                sellTax: null,
                isHoneypot: false,
                hasLiquidity: false,
                isLiquidityBurned: false,
                liquidityBurnPercentage: 0,
                warning: "Failed to scan token. Exercise caution when trading.",
                isLoading: false
            })
        }
    }

    scanToken()
  }, [currency, getTaxInfo, checkLiquidityBurned, router])

  return scanResult
}
